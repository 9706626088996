import { ContactDetails } from '@app/shared/models/contact-details';
import { CountyEmployee } from '@app/shared/models/county-employee';
import { Name } from '@app/shared/models/name';
import { User } from '@app/shared/models/user';
import { AuthService, AuthUser } from '@app/shared/services/auth.service';
import { FirestoreObservableDocument } from '@app/shared/util/firestore-observable-document';
import * as util from '@app/shared/util';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, ReplaySubject, Subject, interval, switchMap } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UserRole } from '@app/shared/models/user-role';
import { County } from '@app/modules/county/models/county';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { filter, takeWhile } from 'rxjs/operators';

export class SignedInUser {
  archived: boolean;
  userId?: string;
  contactDetails: ContactDetails;
  email?: string;
  countyId?: string;
  name?: Name;
  isClient: boolean;
  isCountyDepotViewer: boolean;
  isCountyOrderViewer: boolean;
  isCountyOrderEditor: boolean;
  isCountyAdmin: boolean;
  isCountyViewer: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  counties?: string[];
  hasMultiFactorAuth?: boolean;
  emailVerified?: boolean;

  constructor(args?: {
    archived?: boolean;
    contactDetails?: ContactDetails;
    countyId?: string;
    email?: string;
    name?: Name;
    userId?: string;
    roles?: any;
    counties?: string[];
    hasMultiFactorAuth?: boolean;
    emailVerified?: boolean;
  }) {
    args = args || {};
    const roles = args.roles || {};

    this.archived = !!args.archived;
    this.userId = args.userId;
    this.contactDetails = args.contactDetails || {};
    this.email = args.email;
    this.name = args.name || ({} as Name);
    this.countyId = args.countyId;
    this.isClient = !args.archived && !!roles.client;
    this.isAdmin = !args.archived && !!roles.admin;
    this.isSuperAdmin = !args.archived && !!roles.super_admin;
    this.isCountyDepotViewer = !args.archived && !!roles.county_depot_viewer;
    this.isCountyOrderViewer = !args.archived && !!roles.county_order_viewer;
    this.isCountyOrderEditor = !args.archived && !!roles.county_order_editor;
    this.isCountyAdmin = !args.archived && !!roles.county_admin;
    this.isCountyViewer = !args.archived && !!roles.county_viewer;
    if (args.counties && !(args.counties.length === 1 && args.counties[0] === 'undefined')) {
      this.counties = args.counties;
    }
    this.hasMultiFactorAuth = args.hasMultiFactorAuth;
    this.emailVerified = args.emailVerified;
  }
}

@Injectable({ providedIn: 'root' })
export class UserService {
  user: SignedInUser = new SignedInUser();
  user$: Observable<SignedInUser | null>;
  noUserLoggedIn$: Subject<boolean> = new Subject();

  private authStateDetermined = false;
  private authStateChanged = false;

  constructor(
    firestore: AngularFirestore,
    private authService: AuthService,
    private performance: AngularFirePerformance,
    private router: Router
  ) {
    this.noUserLoggedIn$.next(true);

    const user$ = new Observable((subscriber) => {
      let userDocSubscription: Subscription;

      const authSubscription = this.authService.user$.subscribe({
      next: (user?: AuthUser) => {
        this.authStateChanged = true;

        if (!user) {
          this.noUserLoggedIn$.next(true);
          if (userDocSubscription) {
            userDocSubscription.unsubscribe();
            userDocSubscription = null;
          }

          this.user = new SignedInUser();
          subscriber.next(null);

          return;
        } else {
          this.noUserLoggedIn$.next(false);
          if (userDocSubscription) {
            return;
          }
        }

        const userDoc$ = new FirestoreObservableDocument<User>(firestore, {
          documentPath: ['users', user.userId]
        }).observable$;

        userDocSubscription = userDoc$.subscribe({
          next: (userDoc: User) => {
            const userDocRoles = userDoc.roles;
            let userDocRolesForActiveCounty;
            // if user is employee, assign corresponding active county roles
            if (
              userDocRoles.counties &&
              userDoc.activeCounty &&
              userDoc.activeCounty !== 'undefined' &&
              userDocRoles.counties[userDoc.activeCounty]
            ) {
              userDocRolesForActiveCounty = userDocRoles.counties[userDoc.activeCounty];
            } else {
              userDocRolesForActiveCounty = {
                county_depot_viewer: false,
                county_order_viewer: false,
                county_order_editor: false,
                county_viewer: false,
                county_admin: false
              };
            }
            const newRoleStructureAsOldRoleStrucutre: { [role in UserRole]: boolean } = {
              client: userDocRoles.client,
              county_depot_viewer: userDocRolesForActiveCounty.county_depot_viewer,
              county_order_viewer: userDocRolesForActiveCounty.county_order_viewer,
              county_order_editor: userDocRolesForActiveCounty.county_order_editor,
              county_admin: userDocRolesForActiveCounty.county_admin,
              county_viewer: userDocRolesForActiveCounty.county_viewer,
              admin: userDocRoles.admin,
              super_admin: userDocRoles.super_admin
            };

            // the new script caused users with no activeCounty to have an activeCounty of string 'undefined', hence this workaround
            let activeCounty;
            if (userDoc.activeCounty && userDoc.activeCounty !== 'undefined') {
              activeCounty = userDoc.activeCounty;
            }

            this.user = new SignedInUser({
              archived: userDoc.archived,
              contactDetails: userDoc.contactDetails,
              countyId: activeCounty,
              email: user.email,
              name: userDoc.name,
              roles: newRoleStructureAsOldRoleStrucutre,
              userId: user.userId,
              counties: userDoc.counties,
              hasMultiFactorAuth: user.hasMultiFactorAuth,
              emailVerified: user.emailVerified
            });

            subscriber.next(this.user);
          },
          error: () => {
            this.user = new SignedInUser();
            subscriber.next(null);
          }
        });
      }});

      return () => {
        authSubscription.unsubscribe();

        if (userDocSubscription) {
          userDocSubscription.unsubscribe();
        }
      };
    });

    const subject = new ReplaySubject<SignedInUser>(1);
    user$.subscribe(subject);
    this.user$ = subject.asObservable();

    this.user$.subscribe(async (user: SignedInUser) => {
      if (!this.authStateDetermined) {
        this.authStateDetermined = true;
        this.authStateChanged = false;
        return;
      }

      if (this.authStateChanged) {
        this.authStateChanged = false;
        this.router.navigate(['']);
        return;
      }

      if (user) {
        if (user.archived) {
          await this.authService.refreshToken(true);
          await this.authService.signOut();
        } else if (this.authStateDetermined) {
          // we need a timeout because it takes time to update the custom user claims in the backend
          await util.sleep(5000);
          this.authService.refreshToken();
        }
      } else if (this.authStateDetermined) {
        await this.authService.signOut();
      }

      this.authStateChanged = false;
    });
  }

  async getUser(): Promise<SignedInUser | null> {
    return util.takeOneAsPromise(this.user$);
  }

  canRequestRepair(user: SignedInUser, county: County): boolean {
    return (user.isClient && county.clientRepairRequest) || (!user.isClient && (user.isCountyAdmin || user.isCountyOrderEditor));
  }

  canRequestAdjustment(user: SignedInUser, county: County): boolean {
    return (user.isClient && county.clientModificationRequest) || (!user.isClient && (user.isCountyAdmin || user.isCountyOrderEditor));
  }

  canRequestPickup(user: SignedInUser, county: County): boolean {
    return (user.isClient && county.clientPickUpRequest) || (!user.isClient && !county.iWMO && (user.isCountyOrderViewer || user.isCountyOrderEditor));
  }
}
